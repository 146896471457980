import {ToastService} from '../services/toast.service';
import {i18n} from '../services/i18n.service';
import {CoinsToAmount} from './payment-utils';
import {RouterNavigate} from './router.utils';
import {ModalService} from "../services/modal.service";
import {IUser} from "../interfaces/user";

export const MomentSuccessfulSavedToast = () => {
    return ToastService.presentToast(i18n('default_toasts_moment').get(), null, 6000, 'success');
}

export const BlockedAccount = () => {
    return ErrorToast(i18n('default_toasts_blocked').get());
}

export const DataSuccessfulSavedToast = () => {
    return ToastService.presentToast(
        i18n('default_toasts_saved').get(),
        false,
        1000,
        'success')
}

export const DataSuccessfulUpdatedToast = () => {
    return ToastService.presentToast(
        i18n('default_toasts_updated').get(),
        false,
        1000,
        'success')
}

export const DataSuccessfulDeletedToast = () => {
    return ToastService.presentToast(
        i18n('default_toasts_deleted').get(),
        false,
        1000,
        'success')
}

export const CheckYourMailAndConfirmToast = () => {
    return ToastService.presentToast(
        i18n('default_toasts_confirmed').get(),
        null,
        1000,
        'success')
}

export const LoggedOutToast = () => {
    return ToastService.presentToast(
        i18n('default_toasts_logged_out').get(),
        null,
        5000,
        'danger');
}

export const SuccessPurchaseSubscriptionToast = (userName: string) => {
    return ToastService.presentToast(
        i18n('default_toasts_subscribed').get(null, {
            ':userName': userName
        }),
        null,
        2000,
        'success',
        'bottom'
    );
}

export const SuccessPurchaseAmountToast = (purchaseAmount:string) => {
    return ToastService.presentToast(
        i18n('default_toasts_credits').get(null, {
            ':purchaseAmount': CoinsToAmount(atob(purchaseAmount))
        }),
        null,
        2000,
        'success',
        'bottom'
    );
}
export const ErrorCreditCardToast = () => {
    return ErrorToast(i18n('default_toasts_charging_cc_error').get(),10000);
}

export const DataUnsuccessfulDeletedToast = () => {
    return ToastService.presentToast(
        i18n('default_toasts_delete_error').get(),
        "Okay",
        0,
        'danger'
    );
}

export const DataUnsuccessfulUpdatedToast = () => {
    return ToastService.presentToast(
        i18n('default_toasts_updating_error').get(),
        "Okay",
        0,
        'danger'
    );
}

export const ErrorPurchaseAmountToast = () => {
    return ToastService.presentToast(
        i18n('default_toasts_charging_error').get(),
        "Okay",
        0,
        'danger'
    );
}

export const SuccessToast = (message:string, duration:number = 0) => {
    return ToastService.presentToast(
        message,
        "Okay",
        duration,
        'success'
    );
}

export const ErrorToast = (message:string, duration:number = 0) => {
    return ToastService.presentToast(
        message,
        "Okay",
        duration,
        'danger'
    );
}

export const NotificationPerMail = (userId:string) => {
    return ToastService.presentToastWithButtons(null, i18n('default_toasts_relevant_news_mail').get(),
        [{
            icon: 'settings-outline',
            handler: () => {
                return RouterNavigate(`/profile/${userId}/settings`);
            }
        },
            {
                text: 'Okay',
                role: "cancel"
            }],
        6000,
        'bottom',
        'success');
}

export const NotificationPerPush = (userId:string) => {
    return ToastService.presentToastWithButtons(null,
        i18n('default_toasts_relevant_news_push').get(),
        [{
            icon: 'settings-outline',
            handler: () => {
                return RouterNavigate(`/profile/${userId}/settings`);
            }
        }, {
            text: 'Okay',
            role: "cancel"
        }],
        6000,
        'bottom',
        'success');
}


export const RestrictionNotification = (user:IUser,account:IUser,errorCode:string) => {
    switch (errorCode){
        case "actionblockedbyuser":
            return ToastService.presentToastWithButtons(null,
                i18n("default_toasts_are_restricted_activity").get(),
                [{
                    icon: 'settings-outline',
                    handler: async () => {
                        await ModalService.openModal("modal-user-pivot-setting", {
                            user:user,
                            accountId:account?._id,
                            restrictText: i18n("default_toasts_restrict_user")
                        },"modal-user-pivot-setting");
                    }
                }, {
                    text: 'Okay',
                    role: "cancel"
                }],
                6000,
                'bottom',
                'danger');
        case "youblockedactionforthisuser":
            return ToastService.presentToastWithButtons(null,
                i18n("default_toasts_has_restricted_activity").get(),
                [{
                    icon: 'settings-outline',
                    handler: async () => {
                        await ModalService.openModal("modal-user-pivot-setting", {
                            user:user,
                            accountId:account?._id,
                            restrictText: i18n("default_toasts_restrict_user")
                        },"modal-user-pivot-setting");
                    }
                }, {
                    text: 'Okay',
                    role: "cancel"
                }],
                6000,
                'bottom',
                'danger');
    }
}

