import {BehaviorSubject} from "rxjs";
import {Article, IArticle} from "../interfaces/article";
import {StorageService} from "./storage.service";
import {ErrorToast} from "../helpers/default-toast";
import {i18n} from "./i18n.service";
import {IEnv} from "../interfaces/env";
import {EnvironmentService} from "./environment.service";
import {AlertConfirm} from "../helpers/alert-utils";
class ShoppingCartServiceController {
  private shoppingCartCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private shoppingCart$: BehaviorSubject<IArticle[]> = new BehaviorSubject<IArticle[]>([]);
  private shoppingCartInternal = [];
  private environment:IEnv = EnvironmentService.getEnvironment();
  public shoppingCart = this.shoppingCart$.asObservable();
  public shoppingCartCount = this.shoppingCartCount$.asObservable();

  constructor() {
    const shoppingCart = StorageService.get('shoppingCart');
    this.shoppingCartInternal = shoppingCart?.map((article:IArticle) => {
      return new Article().deserialize(article);
    }) || [];
    this.shoppingCart$.next(this.shoppingCartInternal)
    this.shoppingCartCount$.next(this.shoppingCartInternal?.length || 0);
  }

  addArticle(article: IArticle,amount = 1){
    const index = this.shoppingCartInternal.findIndex(article$ => article$.connect_id === article.connect_id);
    if(index !== -1){
      this.shoppingCartInternal[index].amount = amount
    }else {
      article["amount"] = amount;
      this.shoppingCartInternal.push(article);
    }
    StorageService.set('shoppingCart', this.shoppingCartInternal);
    this.shoppingCart$.next(this.shoppingCartInternal)
    this.shoppingCartCount$.next(this.shoppingCartInternal?.length || 0);
  }


  getArticle(article: IArticle){
    return this.shoppingCartInternal.find(article$ => article$.connect_id === article.connect_id);
  }

  changeAmount(article: IArticle, amount: number) {
    if (this.environment.privateShop || amount <= article.stock) {
      if (amount <= 0) {
        ShoppingCartService.removeArticle(article)
      }else{
        ShoppingCartService.addArticle(article, amount);
      }
    }else{
      ErrorToast(i18n('The maximum stock is ').t('de','Der maximale Lagerbestand beträgt ').get() + article.stock, 5000).then();
    }
  }

  async removeArticle(article: IArticle) {
    return new Promise<void>((resolve, reject) => {
      AlertConfirm(
          i18n('Delete Article').t('de', 'Artikel entfernen').get(),
          i18n(`Are you sure you really want to remove this article?`).t('de', `Möchten Sie diesen Artikel wirklich entfernen?`).get(),
          () => {
            this.shoppingCartInternal = this.shoppingCartInternal.filter(article$ => article$.connect_id !== article.connect_id);
            StorageService.set('shoppingCart', this.shoppingCartInternal);
            this.shoppingCart$.next(this.shoppingCartInternal);
            this.shoppingCartCount$.next(this.shoppingCartInternal?.length || 0);
            resolve();  // Resolve the promise when done
          },
          () => {
            reject();  // Reject in case of cancel
          },
          i18n("Remove").t('de', 'Entfernen').get(),
          i18n("cancel").t('de', 'Abbrechen').get(),
      );
    });
  }

  clear(){
    this.shoppingCartInternal = [];
    StorageService.set('shoppingCart', this.shoppingCartInternal);
    this.shoppingCart$.next(this.shoppingCartInternal)
    this.shoppingCartCount$.next(this.shoppingCartInternal?.length || 0);
  }


}


export const ShoppingCartService = new ShoppingCartServiceController();
