
import {Deserializable} from './deserialize';


export interface IArticle {
  always_in_stock?: number;
  token?: number;
  delivery_time?:number;
  category?: string[];
  connect_id: number;
  quantity?: number;
  created_at?: string;
  deleted_at?: string;
  description?: string;
  image_date: string;
  amount?:number;
  id: string;
  _id: string;
  image_source_url?: string;
  main_image?: string;
  name?: string;
  title?: string;
  preview_url?: string;
  packaging_amount?: number;
  price?: number;
  images?: string[];
  customer_price?:  [{
    scale: number,
    price: number
  }],
  industry_price?: [{
    scale: number,
    price: number
  }];
  stock?: number;
  totalPrice?: number;
  totalAmount?: number;
  type?: string;
  options?: any;
  selectedOptions?: any;
  updated_at?: string;
  hasOptionSelected?:boolean;
  quantity_grouping?:boolean;
  virtual?: boolean
  selectedOption?:any;
  value?: number;
  vat?: number;
  collection?: any;
  file_stack_id?: string;
  summarizeOptions?: any;
}

export class Article implements IArticle, Deserializable {
  always_in_stock?: number;
  token?: number;
  delivery_time?:number;
  category?: string[];
  connect_id: number;
  quantity?: number;
  created_at?: string;
  deleted_at?: string;
  description?: string;
  image_date: string;
  amount?:number;
  id: string;
  _id: string
  image_source_url?: string;
  main_image?: string;
  name?: string;
  title?: string;
  preview_url?: string;
  packaging_amount?: number;
  price?: number;
  images?: string[];
  customer_price?:  [{
    scale: number,
    price: number
  }];
  industry_price?: [{
    scale: number,
    price: number
  }];
  stock?: number;
  totalPrice?: number;
  totalAmount?: number;
  type?: string;
  options?: any;
  selectedOptions?: any;
  updated_at?: string;
  hasOptionSelected?:boolean;
  quantity_grouping?:boolean;
  virtual?: boolean;
  selectedOption?:any;
  value?: number;
  vat?: number;
  collection?: any;
  file_stack_id?: string;
  summarizeOptions?: any;


  deserialize(input: any) {
    Object.assign(this, input);
    this.virtual = (input.type == "Virtuell")
    this.hasOptionSelected = (!input?.options)
    this.id = input.id;
    return this;
  }
}

